// Convert links to perform POST requests instead of GET
document.addEventListener('DOMContentLoaded', function () {
    const postLinks = document.querySelectorAll('a[data-method="POST"]');
    Array.from(postLinks).map(link => {
        const form = document.createElement('form');
        form.method = "POST";
        form.action = link.href;
        const csrfInput = document.createElement('input');
        csrfInput.type = 'hidden';
        csrfInput.name = 'csrfmiddlewaretoken';
        csrfInput.value = link.dataset.csrfToken;
        form.append(csrfInput);
        let callback = form.submit;
        if (link.dataset.confirm !== undefined) {
            callback = function () {
                if (confirm(link.dataset.confirm)) {
                    form.submit();
                }
                return false;
            }
        }
        document.body.append(form);
        link.onclick = callback;
    });
});