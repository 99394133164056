const store = () => {return {
    userSetting: {
        filterSettingDisplay: [ // Array of objects for display filter setting
            { id: 0, label: "active", value: true }, // Default only active advertisters show
            { id: 1, label: "archived", value: false }
        ],
    },
    state: {
        ready: false,  // if the store is ready to be rendered
    },
    init() {
        this.state.ready = true
    },
    /* To convert data got from user setting API to display filter array format
    ** @param {archivedFilter} expected to be "active" / "all" / "archived"
    */
    setFilterSettingDisplay(archivedFilter) {
        let userSettingFilter = {};
        switch(archivedFilter) { 
            case 'active': { 
                // Show active advertisers only
                userSettingFilter = {
                    active : true,
                    archived: false
                };
                break; 
            } 
            // Uncomment to add feature to show archived advertisers only
            // Other logic should took care of this case already
            // case 'archived': { 
            //     // Show archived advertisers only
            //     this.setFilterDisplay({
            //         active : false,
            //         archived: true
            //     });
            //     this.getAdvertisers('paging');
            //     break;
            // } 
            case 'all': {
                // Show both active and archived advertisers
                userSettingFilter = {
                    active : true,
                    archived: true
                };
                break;
            } 
            default: { 
                break; 
            } 
        }
        this.userSetting.filterSettingDisplay.forEach(function (setting) {
            if(setting.label === 'active') {
                setting.value = userSettingFilter.active;
            }else{
                setting.value = userSettingFilter.archived;
            }
        })
    },
    /* Return the value of `website_status` as a param based on filterSettingDisplay
    ** Return values (string) : 'all', 'archived', null
    ** website_status = null : Will not pass this param and ONLY list of active advertiser will return 
    ** website_status = all : Both active and archived advertister will return
    ** website_status = archived : Only list of archived advertiser will return
    */
    getFilterSettingParamValue() {
        let _selectedFilter = [];
        this.userSetting.filterSettingDisplay.forEach(function (setting) {
            if(setting.value) _selectedFilter.push(setting.label);
        })
        if(_selectedFilter.length == 2) {
            return 'all'; // Show both
        }else if(_selectedFilter[0] === 'active') {
            return null; // Show active only
        }
        return 'archived'; // Show archived only
    }
}}

export {
    store
};
