// // Javascript to enable link to tab
document.addEventListener('DOMContentLoaded', function () {
    var hash = document.location.hash;
    var prefix = "tab_";
    if (hash) {
        var tabLink = document.querySelector('[role=tablist] a[href="' + hash.replace(prefix, "") + '"]');
        if (tabLink) {
            var tab = new bootstrap.Tab(tabLink);
            tab.show();
        }
    }

    // Change hash for page-reload
    document.querySelectorAll('[role=tablist] a').forEach(function (tabLink) {
        tabLink.addEventListener('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash.replace("#", "#" + prefix);
        });
    });
});
