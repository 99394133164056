// TODO: What does this do?  Maybe it should go away...
export function domDecoder (str) {
                // Make sure string is passed
                if (typeof str === 'string' || str instanceof String) {
                    let parser = new DOMParser();
                    let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
                    return dom.body.textContent;
                    }
                else {
                    return str
                }
                }