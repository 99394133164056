
let CustomFooter = {
  name: "custom-footer",
  props: {
    privacyPolicyUrl: String,
    contactUsUrl: String
  },
  template: `
    <nav class="vue-footer navbar mint-background fixed-bottom border-top border-white">
        <div class="container p-0">
            <div class="row w-100 align-items-center font-size-sm">
              <div class="col-9">
                <ul class="nav">
                  <li class="nav-item">
                      <a class="nav-link" :href="privacyPolicyUrl">Privacy Policy</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :href="contactUsUrl">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div class="col-3 text-right pr-0">
                <p class="mb-0">&copy; AdRetriever Inc. 2024</p>
              </div>
            </div>
        </div>
    </nav>
    `,
}

export { CustomFooter }
