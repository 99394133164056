import { CustomFooter } from './CustomFooter';
import { NavBar } from './NavBar';
import {WebsitesService} from "./common/api.service.js";
import { usePermissionsStore } from './store/PermissionStore.js';
import { mapActions } from 'pinia';

export default {
    name: 'Wrapper',
    props: {
        Basic: Object,
        Wrapper: {
            type: Object,
            required: true
        },
    },
    components: {
        'custom-footer': CustomFooter,
        'nav-bar': NavBar
    },
    methods: {
        ...mapActions(usePermissionsStore, ['fetchPermissions']),
        updateStore(permissions) {
            this.store.init(permissions);
        }
    },
    mounted () {
        if (this.Basic.isAuthenticated) {
            if (this.Basic.websiteId || this.Basic.accountId) {
                this.fetchPermissions(this.Basic.accountId, this.Basic.websiteName)
            }
        }
    },
    computed: {
        navBarProps() {
            // Handle if user not logged in
            if (this.Basic.websiteId) {
                return {
                    ...this.Basic,
                    advertiserMenuProps: { ...this.Wrapper.AdvertiserMenu },
                    websiteMenuProps: { ...this.Wrapper.WebsiteMenu },
                    accountMenuProps: { ...this.Wrapper.AccountMenu },
                    userMenuProps: { ...this.Wrapper.UserMenu },
                }
            } else if (this.Basic.accountId) {
                return {
                    ...this.Basic,
                    accountMenuProps: { ...this.Wrapper.AccountMenu },
                    userMenuProps: { ...this.Wrapper.UserMenu },
                }
            } else {
                return {
                    ...this.Basic
                }
            }
        },
    },
    template:
        `
        <div id="wrapper">
            <nav-bar v-bind="{ ...navBarProps }" />
            <slot></slot>
            <custom-footer v-bind="Wrapper.Footer" />
       </div>
    `
}
