import { DogPlusSvg } from "../components/DogPlusSvg.js"

export const RetrieveMoreItems = {
    data() {
        return {
            hover: false
        }
    },
    props: {
        staticUrl: String,
        modalKey: String,
        colorClass: String
    },
    methods: {
        hideModal() {
            this.$emit('hideModal')
        },
        goToBillingPage(){
            this.$emit('goToBillingPage')
        }
    },
    components: {
        DogPlusSvg,
    },
    template: `             
    <div class="invite-collaborators-modal-template-container tier-modal-template container my-4">
        <div class="row justify-content-between px-4">
            <div class="left-container col-5 p-0 d-flex flex-column justify-content-between">
                <div>
                    <h2 class="eggplant-color font-weight-bold mb-4">Retrieve More Items</h2>
                    <p class="mb-4">Looks like you've hit your limit of items! Upgrade your plan to fetch more items.</p>
                    <p class="eggplant-color font-weight-bold mb-4">Upgrade to access our video ad designer</p>
                    <p class="mb-4">With our display ad designer, you'll have tails wagging and engaging ads in no time. Upgrade now to unlock this paw-some feature and unleash your creativity!</p>
                </div>
                <div class="text-center">
                    <b-button class="btn-upgrade-plan w-100" variant="outline-dark mb-3" @click="goToBillingPage">
                        <DogPlusSvg :fillColorClass="colorClass" />Upgrade Plan
                    </b-button>
                    <b-link class="text-center underline disable-color" @click="hideModal"><u>No, I don’t want display ads.</u></b-link>
                </div>
            </div>
            <div class="right-container col-6 default-theme-border-radius">
                <img class="w-100 rounded" :src="staticUrl + 'img/tier_modals/' +  modalKey +'.png'">
            </div>
        </div>
        
    </div>

    `
}
