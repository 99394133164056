import { UpgradeFeatureModal } from "./UpgradeFeatureModal.js";
import { DogPlusSvg } from "./components/DogPlusSvg.js"

export const TierUpgradeButton = {
    data: function () {
        return ({
            dropDownColorClass: "teal-lk-spruce"
        })
    },
    props: {
        buttonText: String,
        modalKey: String,
        staticUrl: String,
        isDropdownItem: {
            type: Boolean,
            default: false
        },
        isShowShadow: {
            type: Boolean,
            default: true
        },
        accountId: String,
        // Default state
        isShowDefault: { // 'Default' meaning showing the button for user has permission to perform action
            type: Boolean,
            default: false
        },
        defaultButtonVariant: {
            type: String,
            default: "primary"
        }
    },
    computed: {
        componentProps: function () {
            return {
                modalKey: this.modalKey,
                staticUrl: this.staticUrl,
                accountId: this.accountId
            }
        },
        btnColorClass() {
            return this.isShowDefault ? "eggplant-marigold-v" : "teal-lk-bone"  // for button with bg as light-sage, we want the dog icon to have teal color, and bone for hover state
        }
    },
    methods: {
        emitDefaultFunction(){
            this.$emit('defaultFunction')
        }
    },
    components: {
        UpgradeFeatureModal,
        DogPlusSvg
    },
    template: `             
    <div class="tier-upgrade-button-container">
        <div v-if="isShowDefault">
            <b-button v-if="!isDropdownItem" class="btn-tier-restriction-default" :variant="defaultButtonVariant" @click="emitDefaultFunction">
                <DogPlusSvg :fillColorClass="btnColorClass" />
                <span>{{ buttonText }}</span>
            </b-button>

            <b-button v-else class="btn-dropdown-tier-restriction mb-0 border-0 p-0" v-b-modal="modalKey" variant="outline-dark">
                <span>{{ buttonText }}</span>
                <DogPlusSvg :fillColorClass="dropDownColorClass" />
            </b-button>
        </div>

        <div v-else>
            <button v-if="!isDropdownItem" type="button" :class="{'shadow' : isShowShadow}" class="btn btn-tier-restriction mx-auto rounded" v-b-modal="modalKey">
                <DogPlusSvg :fillColorClass="btnColorClass" />
                <span>{{ buttonText }}</span>
                <UpgradeFeatureModal 
                v-bind="{...componentProps}"
                ></UpgradeFeatureModal>
            </button> 
            <b-button v-else class="btn-dropdown-tier-restriction mb-0 border-0 p-0" v-b-modal="modalKey" variant="outline-dark">
                <span>{{ buttonText }}</span>
                <DogPlusSvg :fillColorClass="dropDownColorClass" />
                <UpgradeFeatureModal 
                v-bind="{...componentProps}"
                ></UpgradeFeatureModal>
            </b-button>
        </div>
    </div>

    `
}
