import { defineStore } from 'pinia';
import { WebsitesService } from "../common/api.service.js";

export const usePermissionsStore = defineStore('permissions', {
    state: () => ({
        permissions: [],
        isInitialized: false,
    }),
    actions: {
        async fetchPermissions(accountId, websiteName) {

            if (!this.isInitialized) {
                if (websiteName) {
                    const response = await WebsitesService.getWebsiteAccountPermissions(websiteName)
                    this.permissions = response;
                    this.isInitialized = true;
                }
                else if (accountId) {
                    const response = await WebsitesService.getAccountPermissions(accountId)
                    this.permissions = response;
                    this.isInitialized = true;
                }
            }
        },
    },
});
