import { AdvertiserCreation } from "./modalTemplates/AdvertiserCreation"
import { InviteCollaborators } from "./modalTemplates/InviteCollaborators"
import { CreatingVideoAds } from "./modalTemplates/CreatingVideoAds"
import { CustomThemesCreation } from "./modalTemplates/CustomThemesCreation"
import { UpgradeYourPlan } from "./modalTemplates/UpgradeYourPlan"
import { CreatingDisplayAds } from "./modalTemplates/CreatingDisplayAds"
import { RetrieveMoreItems } from "./modalTemplates/RetrieveMoreItems"

let UpgradeFeatureModal = {
  props: {
    modalKey: String,
    staticUrl: String,
    accountId: String
  },
  data() {
    return {
      colorClass: 'teal-lk-bone' // for button with bg as light-sage, we want the dog icon to have teal color, and have bone color when hover
    }
  },
  methods: {
    hideModal() {
      this.$refs[this.modalKey].hide()
    },
    goToBillingPage() {
      window.location.href = window.location.origin + "/account/" + this.accountId +  "/billing";
    }
  },
  components: {
    AdvertiserCreation,
    InviteCollaborators,
    CreatingVideoAds,
    CustomThemesCreation,
    UpgradeYourPlan,
    CreatingDisplayAds,
    RetrieveMoreItems
  },
  template: `
        <b-modal
        :id="modalKey"
        :ref="modalKey"
        hide-header
        hide-footer
        size="xl">
          <component :is="modalKey" :staticUrl="staticUrl" :modalKey="modalKey" @hideModal="hideModal" @goToBillingPage="goToBillingPage" :colorClass="colorClass"></component>   
        </b-modal>
`
};

export { UpgradeFeatureModal };
